<template>
  <v-container id="settings" class="py-0">
    <v-row>
      <router-view
        :options="options"
        :mode="mode"
        :user="selectedEmployee"
        :updateStaff="updateStaff"
        :company="selectedCompany"
        :store="selectedStore"
        :updateStores="updateStores"
        :loading="loading"
        @mode="setMode"
        @event="checkEvent"
      />
    </v-row>

    <base-snackbar :props="snack" :show="show" @close="show = false" />
    <v-dialog
      class="settingsDialog"
      v-model="dialog.show"
      :width="dialog.width"
      persistent
    >
      <v-card class="pa-3">
        <v-card-title class="pa-2 d-flex justify-space-between">
          {{ $t(`confirm.${dialog.type}.title`) }}
          <v-btn
            class="close-dialog mt-n4 mr-n2"
            v-if="dialog.closeBtn"
            @click="closeDialog"
            :ripple="false"
            small
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="subtitle-2 px-2 pt-2 pb-4" v-html="dialog.msg">
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            class="privat--text font-weight-bold"
            elevation="0"
            width="37%"
            text
            :disabled="loading"
            @click="closeDialog"
          >
            {{ $t("action.cancel") }}
          </v-btn>
          <v-btn
            class="red--text font-weight-bold"
            elevation="0"
            width="57%"
            text
            :disabled="loading"
            :loading="loading"
            @click="confirmDialog(dialog.action)"
          >
            {{ $t(`action.${dialog.action}`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import showSnack from "@/mixins/showSnack.mixins";
import errorHandler from "@/utils/errorHandler";
import * as settingsServices from "@/services/settings.services";

export default {
  name: "settings",
  mixins: [showSnack],
  data() {
    return {
      loading: false,
      mode: "main",
      employeePositions: [],
      options: {
        stores: [],
        allStores: [],
        regions: [],
        positions: [],
        companies: [],
        staff: []
      },
      selectedCompany: null,
      selectedEmployee: null,
      selectedStore: null,
      dialog: {
        show: false,
        width: 320,
        closeBtn: false,
        type: "attention",
        msg: "",
        action: "delete"
      },
      updateStaff: false,
      updateStores: false,
      routePath: ""
    };
  },
  computed: {
    ...mapGetters([
      "getDefSettings",
      "getStatus",
      "getPointOfEntry",
      "getAllOrg",
      "getStaff",
      "getStores",
      "getRegions",
      "getOrdersAndSettingsOptions"
    ]),
    textAllStores() {
      return this.$i18n.t("options.allStores");
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    getOrgId() {
      return this.$route.query.orgId;
    }
  },
  methods: {
    showDialog(type, width) {
      this.dialog.width = width;
      this.dialog.type = type;
      this.dialog.msg = this.$i18n.t(`confirm.${type}.msg`);
      this.dialog.action = type;
      this.dialog.show = true;
    },
    closeDialog() {
      this.employeePositions = [];
      this.dialog.show = false;
    },
    confirmDialog(action) {
      let employeeId = this.selectedEmployee.employeeId;

      switch (action) {
        case "remove":
          this.actionEmployee(employeeId, "remove");
          break;
        case "activate":
          this.actionEmployee(employeeId, "activate");
          break;
        case "reactive":
          this.actionEmployee(employeeId, "activate");
          break;
        case "lock":
          this.actionEmployee(employeeId, "lock");
          break;
        case "unlock":
          this.actionEmployee(employeeId, "unlock");
          break;
        case "block":
          this.actionEmployeeEsa(employeeId, "block");
          break;
        case "unblock":
          this.actionEmployeeEsa(employeeId, "unblock");
          break;
      }
    },
    setMode(data) {
      let [mode, push] = data;

      if (mode) {
        this.mode = mode;
        push ? this.$router.push(`/settings/${mode}`) : false;
      } else {
        this.mode = "main";
      }
    },
    getDefaultSettings() {
      this.$store
        .dispatch("getDefaultSettings")
        .then(() => {
          let data = this.getDefSettings;

          if (data) {
            this.createSettingsOptions(data);
          } else {
            let errorCode = this.getStatus || "getDefaultOptions";

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            console.log("then getDefaultSettings error - ", errorCode);
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getDefaultSettings");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getDefaultSettings");
        });
    },
    createSettingsOptions(data) {
      this.createOptionsStores(data.availableStores);
      this.createOptionsPositions(data.subOrdinateRoles);
      this.updateStores = !this.updateStores;
    },
    createOptionsStores(data) {
      let newDataStores;

      this.options.allStores = [
        {
          id: "ALL",
          address: this.textAllStores,
          storeId: "ALL",
          storeName: this.textAllStores
        }
      ];
      newDataStores = data.map(obj => {
        return Object.assign(obj, {
          storeId: obj.id,
          storeName: obj.storeName || obj.name,
          _sortManager: `${obj.managerName} ${obj.managerLdap}`
        });
      });
      data ? this.options.allStores.push(...newDataStores) : false;
      data ? this.options.stores.push(...newDataStores) : false;
    },
    createOptionsPositions(data) {
      this.options.positions = data.map(obj => {
        return Object.assign(obj, {
          label: this.$i18n.t(`options.positions.${obj.value}`)
        });
      });
    },
    checkEvent(eventData) {
      let [event, data] = eventData;

      switch (event) {
        case "addEmployee":
          this.authEsa
            ? this.sendEmployeeEsa(data, "add")
            : this.sendEmployee(data, "add");
          break;
        case "editEmployee":
          this.selectedEmployee = data;
          this.authEsa
            ? this.$router.push(
                `/settings/employee/edit?orgId=${data._orgId}&id=${data.employeeId}`
              )
            : this.$router.push(
                `/settings/employee/edit?id=${data.employeeId}`
              );
          this.mode = "employee/edit";
          break;
        case "updateEmployee":
          this.authEsa
            ? this.sendEmployeeEsa(data, "update")
            : this.sendEmployee(data, "update");
          break;
        case "removeEmployee":
          this.selectedEmployee = data;
          this.showDialog("remove", 370, data.employeeId);
          break;
        case "showDialogActiveEmployee":
          this.selectedEmployee = data;
          this.showDialog("activate", 370, data.employeeId);
          break;
        case "showDialogReactiveEmployee":
          this.selectedEmployee = data;
          this.showDialog("reactivate", 370, data.employeeId);
          break;
        case "showDialogLockEmployee":
          this.selectedEmployee = data;
          this.showDialog("lock", 370, data.employeeId);
          break;
        case "showDialogUnlockEmployee":
          this.selectedEmployee = data;
          this.showDialog("unlock", 370, data.employeeId);
          break;
        case "showDialogBlockEmployee":
          this.selectedEmployee = data;
          this.showDialog("block", 370, data.employeeId);
          break;
        case "showDialogUnblockEmployee":
          this.selectedEmployee = data;
          this.showDialog("unblock", 370, data.employeeId);
          break;
        case "addStore":
          this.sendStore(data, "add");
          break;
        case "editStore":
          this.selectedStore = data;
          this.authEsa
            ? this.$router.push(
                `/settings/store/edit?orgId=${data.orgId}&id=${data.id}`
              )
            : false;
          this.mode = "store/edit";
          break;
        case "updateStore":
          this.sendStore(data, "update");
          break;
        case "removeStore":
          this.selectedStore = data;
          this.showDialog("removeStore", 370, data.id);
          break;
        case "addCompany":
          this.sendCompany(data, "add");
          break;
        case "openCompany":
          this.selectedCompany = data;
          this.$router.push(`/settings/staff?orgId=${data.id}`);
          break;
        case "editCompany":
          this.mode = "company/edit";
          this.selectedCompany = data;
          this.$router.push(`/settings/company/edit?orgId=${data.id}`);
          break;
        case "updateCompany":
          this.sendCompany(data, "update");
          break;
        case "getEsaDefaultSettings":
          this.getEsaDefaultSettings();
          break;
        case "getCompanies":
          this.getCompanies();
          break;
      }
    },
    sendEmployee(data, type) {
      this.loading = true;
      settingsServices
        .sendEmployee(data, type)
        .then(() => {
          this.showSnack("success", [this.$i18n.t(`success.${type}Employee`)]);
          this.timeout = setTimeout(() => {
            this.$router.push("/settings/staff");
          }, 500);
        })
        .catch(err => {
          let errorCode = errorHandler(err, `error.${type}Employee`);

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log(`catch sendEmployee ${type}`);
        })
        .finally(() => {
          this.staffUpdate();
        });
    },
    sendEmployeeEsa(data, type) {
      this.loading = true;
      settingsServices
        .sendEmployeeEsa(data, type)
        .then(() => {
          this.showSnack("success", [this.$i18n.t(`success.${type}Employee`)]);
          this.timeout = setTimeout(() => {
            this.$router.push(`/settings/staff?orgId=${data.orgId}`);
          }, 500);
        })
        .catch(err => {
          let errorCode = errorHandler(err, `error.${type}Employee`);

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log(`catch sendEmployeeEsa ${type}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    actionEmployee(id, type) {
      settingsServices
        .actionEmployee(id, type)
        .then(() => {
          this.showSnack("success", [this.$i18n.t(`success.${type}Employee`)]);
        })
        .catch(err => {
          let errorCode = errorHandler(err, `error.${type}Employee`);

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log(`catch actionEmployee ${type}`);
        })
        .finally(() => {
          this.staffUpdate();
        });
    },
    actionEmployeeEsa(id, type) {
      this.loading = true;
      settingsServices
        .actionEmployeeEsa(id, type)
        .then(() => {
          this.showSnack("success", [this.$i18n.t(`success.${type}Employee`)]);
        })
        .catch(err => {
          let errorCode = errorHandler(err, `error.${type}Employee`);

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log(`catch actionEmployeeEsa ${type}`);
        })
        .finally(() => {
          this.staffUpdate();
        });
    },
    staffUpdate() {
      this.dialog.show = false;
      this.loading = false;
      this.updateStaff = !this.updateStaff;
    },
    storesUpdate() {
      this.dialog.show = false;
      this.loading = false;
      this.updateStores = !this.updateStores;
    },
    sendStore(data, type) {
      this.loading = true;
      settingsServices
        .sendStore(data, type)
        .then(() => {
          this.showSnack("success", [this.$i18n.t(`success.${type}Store`)]);
          this.timeout = setTimeout(() => {
            this.$router.push(`/settings/stores?orgId=${data.orgId}`);
          }, 500);
        })
        .catch(err => {
          let errorCode = errorHandler(err, `error.${type}Store`);

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log(`catch sendStore ${type}`);
        })
        .finally(() => {
          this.storesUpdate();
        });
    },
    getCompanies() {
      this.$store
        .dispatch("getCompanies")
        .then(() => {
          this.options.companies = this.getAllOrg;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "GET_DATA");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getCompanies");
        });
    },
    sendCompany(data, type) {
      this.loading = true;
      settingsServices
        .sendCompany(data, type)
        .then(() => {
          this.showSnack("success", [this.$i18n.t(`success.${type}Company`)]);
          this.getCompanies();
          this.timeout = setTimeout(() => {
            this.$router.push(`/settings/companies`);
          }, 500);
        })
        .catch(err => {
          let errorCode = errorHandler(err, `error.${type}Company`);

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log(`catch sendCompany ${type}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getEsaDefaultSettings() {
      this.$store
        .dispatch("getEsaOrdersDefaultOptions")
        .then(() => {
          let data = this.getOrdersAndSettingsOptions;

          if (data) {
            this.createEsaSettingsOptions(data);
          } else {
            let errorCode = this.getStatus || "getDefaultOptions";

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            console.log("then getEsaOrdersDefaultOptions error");
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getDefaultOptions");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getEsaOrdersDefaultOptions");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createEsaSettingsOptions(data) {
      this.createOptionsCompanyStores(data.ogrDataFilterCps);
      this.createOptionsPositions(data.employeeBinds);
    },
    createOptionsCompanyStores(data) {
      let companyStores;

      this.options.allStores = [];
      this.options.stores = [];
      this.options.allStores = [
        {
          id: "ALL",
          address: this.textAllStores,
          storeId: "ALL",
          storeName: this.textAllStores
        }
      ];

      companyStores = data.find(el => el.orgId === +this.getOrgId)
        .storeFilterSet;

      data ? this.options.allStores.push(...companyStores) : false;
      data ? this.options.stores.push(...companyStores) : false;
    },
    init() {
      this.getDefaultSettings();
    },
    initEsa() {
      this.getCompanies();
    }
  },
  mounted() {
    let nextRoute = this.$route.path.split("/")[2];

    if (this.authEsa) {
      this.initEsa();
      !nextRoute ? this.$router.replace("/settings/companies") : false;
    } else {
      this.init();
      !nextRoute ? this.$router.replace("/settings/staff") : false;
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  }
};
</script>

<style scoped lang="scss">
#settings {
  @media (min-width: 1264px) {
    max-width: 95%;
  }
  @media (min-width: 960px) {
    max-width: 95%;
  }
  .container {
    width: 100%;
    min-width: 100%;
  }
}
</style>
